import React from 'react';
import { graphql } from 'gatsby';

import BaseLayout from '../../components/templates/BaseLayout';
import PrimaryHeader from '../../components/atoms/typography/PrimaryHeader';
import SubHeader from '../../components/atoms/typography/SubHeader';
import Headline from '../../components/atoms/typography/Headline';
import Spacer from '../../components/atoms/utility/Spacer';
import SEO from '../../components/atoms/utility/seo';
import JumboPanel from '../../components/atoms/JumboPanel';
import DownloadAppCTA from '../../components/atoms/callouts/DownloadAppCTA';

const marketplaceFeatures = {
  ['Compete.']: [
    {
      name: 'Do What Students Do',
      description:
        'Welcome to flockx, an app designed to help University of Texas students find fun things to do in Austin, Texas! Whether you’re looking for a restaurant, a study break, or a secret speakeasy, flockx has you covered.'
    },
    {
      name: 'Never Miss Out',
      description:
        'Our app makes it easy to find activities and events happening near you, and you can even see the type of event and join that community. Explore the city, meet new people, and make memories that’ll last a lifetime.'
    }
  ],
  ['Discover.']: [
    {
      name: 'Dirty 6th',
      description:
        'For the true Austin experience, live music haven, and not to mention the perpetual drink deals, Dirty 6th is king. Download the app to find where live music has been spotted today.'
    },
    {
      name: 'Rainey Street',
      description:
        'Feeling a bit more sophisticated? Try one of the dog-friendly bars or restaurants on the charming rainey street. 1920’s houses meet high-rises on this twisted up peninsula.'
    },
    {
      name: 'Austin FC Stadium',
      description:
        'Catch an Austin FC game or grab a beer and watch the game at one of the breweries in walking distance. Also check the flockx app for vibes spotted at The Domain nearby.'
    }
  ],
  ['Level Up.']: [
    {
      name: 'Community Reputation',
      description:
        'Share cool spots and the memories you’ve had with your communities to increase your clout.'
    },
    {
      name: 'Community Rating',
      description:
        'Rate your memories using ratings that matter to your community to help community members grow.'
    },
    {
      name: 'Community Support',
      description:
        'Help new community members learn about your community and where to get started.'
    }
  ]
};

export default function UTPage({
                                 data: { UTHeroImg, UTMobileHeroImg, buildImg, growImg, openGraphImg }
                               }) {
  const jumboPanelOne = {
    image: {
      imageSrc: buildImg,
      imageAlt: 'ACL music fetsival and Austin skyline' //image 1 alt tag
    },
    headline: {},
    paragraph: {},
    list: {
      name: 'Explore Austin', //change name for section 1
      listContent: marketplaceFeatures['Compete.'],
      headerColor: '#7593FF' //font colour
    }
  };

  const jumboPanelTwo = {
    image: {
      imageSrc: growImg,
      imageAlt: 'Dirty 6th Street at night time' //image 2 alt tag
    },
    headline: {},
    paragraph: {},
    list: {
      name: 'Discover Popular Districts', //change name for section 2
      listContent: marketplaceFeatures['Discover.'],
      headerColor: '#63D2E1' //font colour
    }
  };

  return (
    <BaseLayout
      heroContent={UTAustinPageHeroContent}
      heroImg={UTHeroImg}
      mobileHeroImg={UTMobileHeroImg}
      heroAlt={'University of Austin Clock Tower'}
    >
      <SEO
        title='University of Texas Austin | Find Things To Do' //meta title for the page
        keywords={[
          'University of Texas',
          'UT',
          'find things to do',
          'communities',
          'Austin',
          '6th Street',
          'Rainey Street',
          'Austin FC',
          'Texas',
          'flockx mobile app',
          'hidden gems'
        ]} //seo key words
        customSeoImage={openGraphImg}
        description={`Never miss out on what happening around University of Texas. Donwload flockx today to stay connected to the pulse of the community`} //brief description for thumbnail
      />
      <Spacer />
      <Spacer className='block lg:hidden' />
      <JumboPanel content={jumboPanelOne} />
      <Spacer />
      <Spacer className='block md:hidden' />
      <JumboPanel content={jumboPanelTwo} imageSide='left' />
      <Spacer />
      <Spacer className='block lg:hidden' />
      <div className='mx-auto text-left sm:text-center max-w-3xl mb-4 lg:mb-12'>
        <Headline
          spanText='Ready for a new way to explore?'
          spanColor='#000'
          className='mx-auto text-center max-w-[350px] md:max-w-xl mb-4 lg:mb-12 capitalize'
        />
        <div className='relative z-10 text-center'>
          <DownloadAppCTA containerStyles='justify-center' />
        </div>
      </div>
      <Spacer />
      <Spacer className='block lg:hidden' />
    </BaseLayout>
  );
}

function UTAustinPageHeroContent() {
  return (
    <div className='-mt-[15%] sm:mt-0'>
      <PrimaryHeader
        headerText='Experiences in Austin for university of Texas students'
        className=' mb-2 sm:mb-4 md:mb-8 lg:mb-8 text-white capitalize font-medium max-w-[390px] sm:max-w-none md:max-w-4xl lg:max-w-5xl lg:mr-auto lg:ml-0'
      />
      <SubHeader
        headerText='Find things to do near UT Campus, Downtown Austin and all the surrounding areas.'
        className='mb-4 md:mb-8 lg:mb-10 xl:mb-12 text-white font-light max-w-xs sm:max-w-sm lg:max-w-4xl sm:mx-0'
      />
      <DownloadAppCTA containerStyles='justify-start' />
      <p className='block sm:pt-0 font-light sm:text-lg md:text-xl lg:text-2xl text-white'>
        Available in the US
      </p>
    </div>
  );
}

export const query = graphql`
    query UTAustinPageQuery {
        UTHeroImg: file(name: { eq: "ut-hero-img" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        UTMobileHeroImg: file(name: { eq: "ut-mobile-thumb" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        buildImg: file(name: { eq: "explore-austin" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        growImg: file(name: { eq: "dicover-austin" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        openGraphImg: file(name: { eq: "web-preview-ut" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;
